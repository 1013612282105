import React from 'react';

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query";
import PageElement from './src/components/page-element';
import './src/bs/bootstrap.css';
import './src/styles/css.css';
import { toast } from "react-toastify";

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error) => {
      error.response.data.detail.length > 0
        ? toast.error(`${error.response.data.detail}`)
        : toast.error("Something went wrong. Please try again later.");
    },
  }),
  queryCache: new QueryCache({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error) => {
      error.response.data.detail.length > 0
        ? toast.error(`${error.response.data.detail}`)
        : toast.error("Something went wrong. Please try again later.");
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 10000,
    },
  },
});

export const wrapPageElement = ({ element }) => {
  return <PageElement>
    <QueryClientProvider client={queryClient}>
      {element}
    </QueryClientProvider>
  </PageElement>;
};
